import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import CustomThumbUpload from "../../../Components/CustomFileUpload/CustomThumbUpload";
import Constant from "../../../utills/Constant";
const AddLiveStream = (props) => {
  const { open, onClose, close, onSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [streamList, setStreamList] = useState({});
  const [selectedImagePrev1, setSelectedImagePrev1] = useState(null);
  const [selectedImageFile1, setSelectedImageFile1] = useState(null);
  const [selectedImagePrev2, setSelectedImagePrev2] = useState(null);
  const [selectedImageFile2, setSelectedImageFile2] = useState(null);
  const [typeCheck, setTypeCheck] = useState(null);
  useEffect(() => {
    fetchStreamList();
  }, []);

  const handleOnChangeImages1 = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedImageFile1(file);
          setSelectedImagePrev1(videoUrl);
          formik.setFieldValue("thumb1", file); // Ensure this line is setting the correct field
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload only (PNG,JPEG,WEBP,JPG)!");
      }
    }
  };

  const handleOnChangeImages2 = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedImageFile2(file);
          setSelectedImagePrev2(videoUrl);
          formik.setFieldValue("thumb2", file); // Ensure this line is setting the correct field
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload only (PNG,JPEG,WEBP,JPG)!");
      }
    }
  };

  // ... existing code ...

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      thumb1: null,
      thumb2: null,
      streamLink: streamList.type1 || "",
      videoLink: streamList.type2 || "",
    },
    validationSchema: Yup.object().shape({
      thumb1: Yup.mixed().required("This field is required."),
      thumb2: Yup.mixed().required("This field is required."),
      streamLink: Yup.string().matches(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Please provide a valid stream link"
      ),
      videoLink: Yup.string().matches(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Please provide a valid video link"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData1 = new FormData();
          formData1.append("type", 1);
          formData1.append("live_stream", values.streamLink);
          if (selectedImageFile1) {
            formData1.append("thumb1", selectedImageFile1);
          }

          const formData2 = new FormData();
          formData2.append("type", 2);
          formData2.append("prayer", values.videoLink);
          if (selectedImageFile2) {
            formData2.append("thumb2", selectedImageFile2);
          }

          if (values.streamLink && selectedImageFile1) {
            await AxiosInstance.post(ApiList.ADD_UPDATE_STREAM, formData1);
          }
          if (values.videoLink && selectedImageFile2) {
            await AxiosInstance.post(ApiList.ADD_UPDATE_STREAM, formData2);
          }

          toast.success("Live stream added successfully");
          setIsLoading(false);
          resetForm();
          fetchStreamList();
          if (onSubmit) onSubmit();
          onClose();
        } catch (error) {
          console.error("Error submitting form", error);
          setIsLoading(false);
        }
      }
    },
  });

  const fetchStreamList = async () => {
    try {
      const response1 = await AxiosInstance.get(ApiList.GET_STREAM, {
        params: { type: 1 },
      });
      const response2 = await AxiosInstance.get(ApiList.GET_STREAM, {
        params: { type: 2 },
      });

      if (response1.data?.s === 1 && response2.data?.s === 1) {
        const streamList1 = response1.data.r;
        const streamList2 = response2.data.r;

        const thumb1Url = streamList1.thumb
          ? `${Constant.BASE_URL}/${streamList1.thumb}`
          : null;
        const thumb2Url = streamList2.thumb
          ? `${Constant.BASE_URL}/${streamList2.thumb}`
          : null;

        setStreamList({
          type1: streamList1.link || "",
          type2: streamList2.link || "",
        });

        setSelectedImagePrev1(thumb1Url); // Set preview for type 1
        setSelectedImagePrev2(thumb2Url); // Set preview for type 2

        // Update Formik initial values
        formik.setValues({
          thumb1: thumb1Url || null,
          thumb2: thumb2Url || null,
          streamLink: streamList1.link || "",
          videoLink: streamList2.link || "",
        });
      } else {
        setStreamList({ type1: "", type2: "" });
        setSelectedImagePrev1(null);
        setSelectedImagePrev2(null);
      }
    } catch (error) {
      console.error("Error fetching stream lists:", error);
      setStreamList({ type1: "", type2: "" });
      setSelectedImagePrev1(null);
      setSelectedImagePrev2(null);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "40px",
            p: 2,
          },
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box
            sx={{
              display: "flex",
              pt: 2,
              pl: 2,
              width: "100%",
              alignItems: "center",
              borderRadius: "40px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <Typography sx={{ fontSize: "32px", fontWeight: "500" }}>
              Live Stream
            </Typography>
          </Box>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomTextField
                  label="Add Live Stream Link"
                  type="text"
                  placeholder="Link"
                  name="streamLink"
                  value={formik.values.streamLink}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={formik.touched.streamLink && formik.errors.streamLink}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomThumbUpload
                  accept="image/*"
                  onDrop={(acceptedFiles, rejectedFiles) =>
                    handleOnChangeImages1(acceptedFiles, rejectedFiles)
                  }
                  selectedFile={selectedImagePrev1}
                  onRemove={(e) => {
                    e.stopPropagation();
                    setSelectedImagePrev1(null);
                    setSelectedImageFile1(null);
                    formik.setFieldValue("thumb1", null);
                  }}
                  typeCheck={typeCheck}
                />
                {formik.touched.thumb1 && formik.errors.thumb1 ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: { xs: "14px", sm: "14px" },
                      marginTop: "5px",
                    }}
                  >
                    {formik.errors.thumb1}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Add Prayer Video Link"
                  type="text"
                  placeholder="Link"
                  name="videoLink"
                  value={formik.values.videoLink}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={formik.touched.videoLink && formik.errors.videoLink}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomThumbUpload
                  accept="image/*"
                  onDrop={(acceptedFiles, rejectedFiles) =>
                    handleOnChangeImages2(acceptedFiles, rejectedFiles)
                  }
                  selectedFile={selectedImagePrev2}
                  onRemove={(e) => {
                    e.stopPropagation();
                    setSelectedImagePrev2(null);
                    setSelectedImageFile2(null);
                    formik.setFieldValue("thumb2", null);
                  }}
                  typeCheck={typeCheck}
                />
                {formik.touched.thumb2 && formik.errors.thumb2 ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: { xs: "14px", sm: "14px" },
                      marginTop: "5px",
                    }}
                  >
                    {formik.errors.thumb2}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            {/* <RadioGroup
              value={formik.values.scheduleSelected}
              onChange={formik.handleChange}
              name="scheduleSelected"
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label="Publish Now"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  label="Schedule Later"
                />
              </Box>
            </RadioGroup> */}
          </DialogContent>
          <Box
            sx={{
              py: 3,
              px: 4,
              display: "flex",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <CustomLoaderButton
              btnTitle={"Submit"}
              sx={{ width: "150px" }}
              type="submit"
              loading={isLoading}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default AddLiveStream;
